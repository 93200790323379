<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.5 15.75C13.5 14.1587 12.8679 12.6326 11.7426 11.5074C10.6174 10.3821 9.0913 9.75 7.5 9.75C5.9087 9.75 4.38258 10.3821 3.25736 11.5074C2.13214 12.6326 1.5 14.1587 1.5 15.75"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M7.5 9.75C9.57107 9.75 11.25 8.07107 11.25 6C11.25 3.92893 9.57107 2.25 7.5 2.25C5.42893 2.25 3.75 3.92893 3.75 6C3.75 8.07107 5.42893 9.75 7.5 9.75Z"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M16.5 15C16.5 12.4725 15 10.125 13.5 9.00002C13.993 8.6301 14.3873 8.14434 14.6479 7.58573C14.9085 7.02711 15.0274 6.41288 14.994 5.79737C14.9606 5.18187 14.776 4.58409 14.4566 4.05692C14.1371 3.52976 13.6926 3.08947 13.1625 2.77502"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>
