<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.00016 2H12.0002L14.6668 6L8.00016 14.6667L1.3335 6L4.00016 2Z" stroke="currentcolor" stroke-width="1.33333"
            stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7.3335 2L5.3335 6L8.00016 14.6667L10.6668 6L8.66683 2" stroke="currentcolor" stroke-width="1.33333"
            stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1.3335 6H14.6668" stroke="currentcolor" stroke-width="1.33333" stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>
