<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.67151 2.4495C8.70389 2.39071 8.75144 2.34168 8.80923 2.30753C8.86701 2.27338 8.9329 2.25537 9.00001 2.25537C9.06713 2.25537 9.13302 2.27338 9.1908 2.30753C9.24858 2.34168 9.29614 2.39071 9.32851 2.4495L11.5425 6.6525C11.5953 6.74983 11.669 6.83425 11.7583 6.89972C11.8476 6.96518 11.9503 7.01007 12.059 7.03115C12.1677 7.05223 12.2797 7.04898 12.387 7.02164C12.4943 6.99429 12.5942 6.94354 12.6795 6.873L15.8873 4.125C15.9488 4.07492 16.0247 4.04567 16.104 4.04145C16.1832 4.03724 16.2618 4.05829 16.3283 4.10156C16.3949 4.14484 16.446 4.20811 16.4743 4.28227C16.5025 4.35643 16.5066 4.43766 16.4858 4.51425L14.3603 12.1988C14.3169 12.356 14.2234 12.4948 14.094 12.5942C13.9646 12.6935 13.8064 12.748 13.6433 12.7493H4.35751C4.19426 12.7481 4.03585 12.6937 3.90631 12.5944C3.77677 12.495 3.68318 12.3561 3.63976 12.1988L1.51501 4.515C1.4942 4.43841 1.49823 4.35718 1.52652 4.28302C1.55481 4.20886 1.6059 4.14559 1.67244 4.10231C1.73898 4.05904 1.81753 4.03799 1.89679 4.0422C1.97605 4.04642 2.05194 4.07567 2.11351 4.12575L5.32051 6.87375C5.40586 6.94429 5.50577 6.99504 5.61306 7.02239C5.72035 7.04973 5.83237 7.05298 5.94106 7.0319C6.04976 7.01082 6.15244 6.96593 6.24173 6.90047C6.33102 6.835 6.40472 6.75058 6.45751 6.65325L8.67151 2.4495Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M3.75 15.75H14.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
