<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.00002 14H3.33335C2.97973 14 2.64059 13.8595 2.39054 13.6095C2.14049 13.3594 2.00002 13.0203 2.00002 12.6667V3.33333C2.00002 2.97971 2.14049 2.64057 2.39054 2.39052C2.64059 2.14048 2.97973 2 3.33335 2H6.00002"
            stroke="currentcolor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10.6667 11.3333L14 7.99996L10.6667 4.66663" stroke="currentcolor" stroke-width="1.33333"
            stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14 8H6.00001" stroke="currentcolor" stroke-width="1.33333" stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>


</template>
