<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.25 9C2.25 10.335 2.64588 11.6401 3.38758 12.7501C4.12928 13.8601 5.18349 14.7253 6.41689 15.2362C7.65029 15.7471 9.00749 15.8808 10.3169 15.6203C11.6262 15.3599 12.829 14.717 13.773 13.773C14.717 12.829 15.3599 11.6262 15.6203 10.3169C15.8808 9.00749 15.7471 7.65029 15.2362 6.41689C14.7253 5.18349 13.8601 4.12928 12.7501 3.38758C11.6401 2.64588 10.335 2.25 9 2.25C7.11296 2.2571 5.30173 2.99342 3.945 4.305L2.25 6"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M2.25 2.25V6H6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M11.25 7.125H7.875C7.57663 7.125 7.29048 7.24353 7.07951 7.45451C6.86853 7.66548 6.75 7.95163 6.75 8.25C6.75 8.54837 6.86853 8.83452 7.07951 9.0455C7.29048 9.25647 7.57663 9.375 7.875 9.375H10.125C10.4234 9.375 10.7095 9.49353 10.9205 9.70451C11.1315 9.91548 11.25 10.2016 11.25 10.5C11.25 10.7984 11.1315 11.0845 10.9205 11.2955C10.7095 11.5065 10.4234 11.625 10.125 11.625H6.75"
            stroke="currentColor" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9 12.75V6" stroke="currentColor" stroke-width="1.125" stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>
