<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.1667 12.4166C16.8333 13.6666 17.5 14.1666 17.5 14.1666H2.5C2.5 14.1666 5 12.5 5 6.66663C5 3.91663 7.25 1.66663 10 1.66663C10.5833 1.66663 11.0833 1.74996 11.5833 1.91663"
            stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M8.58334 17.5C8.72283 17.7537 8.92788 17.9653 9.17709 18.1127C9.42629 18.26 9.71049 18.3378 10 18.3378C10.2895 18.3378 10.5737 18.26 10.8229 18.1127C11.0721 17.9653 11.2772 17.7537 11.4167 17.5"
            stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M15 9.16663C16.3807 9.16663 17.5 8.04734 17.5 6.66663C17.5 5.28591 16.3807 4.16663 15 4.16663C13.6193 4.16663 12.5 5.28591 12.5 6.66663C12.5 8.04734 13.6193 9.16663 15 9.16663Z"
            fill="#B1E346" stroke="#B1E346" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>
