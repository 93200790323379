<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.00002 7.33337H4.00002C4.35364 7.33337 4.69278 7.47385 4.94282 7.7239C5.19287 7.97395 5.33335 8.31309 5.33335 8.66671V10.6667C5.33335 11.0203 5.19287 11.3595 4.94282 11.6095C4.69278 11.8596 4.35364 12 4.00002 12H3.33335C2.97973 12 2.64059 11.8596 2.39054 11.6095C2.14049 11.3595 2.00002 11.0203 2.00002 10.6667V7.33337ZM2.00002 7.33337C2.00002 6.54544 2.15521 5.76523 2.45674 5.03727C2.75827 4.30932 3.20022 3.64788 3.75737 3.09073C4.31453 2.53358 4.97596 2.09163 5.70391 1.7901C6.43187 1.48857 7.21208 1.33337 8.00002 1.33337C8.78795 1.33337 9.56816 1.48857 10.2961 1.7901C11.0241 2.09163 11.6855 2.53358 12.2427 3.09073C12.7998 3.64788 13.2418 4.30932 13.5433 5.03727C13.8448 5.76523 14 6.54544 14 7.33337M14 7.33337V10.6667C14 11.0203 13.8595 11.3595 13.6095 11.6095C13.3594 11.8596 13.0203 12 12.6667 12H12C11.6464 12 11.3073 11.8596 11.0572 11.6095C10.8072 11.3595 10.6667 11.0203 10.6667 10.6667V8.66671C10.6667 8.31309 10.8072 7.97395 11.0572 7.7239C11.3073 7.47385 11.6464 7.33337 12 7.33337H14Z"
            stroke="currentcolor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M14 10.6667V12.0001C14 12.7073 13.7191 13.3856 13.219 13.8857C12.7189 14.3858 12.0406 14.6667 11.3333 14.6667H8.00001"
            stroke="currentcolor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

</template>
