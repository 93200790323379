<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.5 15.75C1.49994 14.5952 1.8331 13.465 2.45951 12.4949C3.08592 11.5248 3.97896 10.7561 5.03145 10.281C6.08394 9.80588 7.25118 9.64459 8.39307 9.81647C9.53496 9.98835 10.603 10.4861 11.469 11.25"
            stroke="#18181B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M7.5 9.75C9.57107 9.75 11.25 8.07107 11.25 6C11.25 3.92893 9.57107 2.25 7.5 2.25C5.42893 2.25 3.75 3.92893 3.75 6C3.75 8.07107 5.42893 9.75 7.5 9.75Z"
            stroke="#18181B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12 14.25L13.5 15.75L16.5 12.75" stroke="#18181B" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>

</template>
