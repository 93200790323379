<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1175_8475)">
            <path d="M10.5 6.75C10.5 7.14782 10.342 7.52936 10.0607 7.81066C9.77936 8.09196 9.39782 8.25 9 8.25H4.5L1.5 11.25V3C1.5 2.175 2.175 1.5 3 1.5H9C9.39782 1.5 9.77936 1.65804 10.0607 1.93934C10.342 2.22064 10.5 2.60218 10.5 3V6.75Z" stroke="#18181B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.5 6.75H15C15.3978 6.75 15.7794 6.90804 16.0607 7.18934C16.342 7.47064 16.5 7.85218 16.5 8.25V16.5L13.5 13.5H9C8.60218 13.5 8.22064 13.342 7.93934 13.0607C7.65804 12.7794 7.5 12.3978 7.5 12V11.25" stroke="#18181B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_1175_8475">
                <rect width="18" height="18" fill="white"/>
            </clipPath>
        </defs>
    </svg>

</template>
