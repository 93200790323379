<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_34_234)">
            <path
                d="M8.25 11.25H9.75C10.1478 11.25 10.5294 11.092 10.8107 10.8107C11.092 10.5294 11.25 10.1478 11.25 9.75C11.25 9.35218 11.092 8.97064 10.8107 8.68934C10.5294 8.40804 10.1478 8.25 9.75 8.25H7.5C7.05 8.25 6.675 8.4 6.45 8.7L2.25 12.75"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M5.25 15.75L6.45 14.7C6.675 14.4 7.05 14.25 7.5 14.25H10.5C11.325 14.25 12.075 13.95 12.6 13.35L16.05 10.05C16.3394 9.77646 16.5083 9.39918 16.5196 9.00114C16.5308 8.60309 16.3835 8.21688 16.11 7.92746C15.8365 7.63804 15.4592 7.46913 15.0612 7.45788C14.6631 7.44662 14.2769 7.59396 13.9875 7.86746L10.8375 10.7925"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.5 12L6 16.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M12 8.92495C13.2012 8.92495 14.175 7.95117 14.175 6.74995C14.175 5.54873 13.2012 4.57495 12 4.57495C10.7988 4.57495 9.82501 5.54873 9.82501 6.74995C9.82501 7.95117 10.7988 8.92495 12 8.92495Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M4.5 6C5.74264 6 6.75 4.99264 6.75 3.75C6.75 2.50736 5.74264 1.5 4.5 1.5C3.25736 1.5 2.25 2.50736 2.25 3.75C2.25 4.99264 3.25736 6 4.5 6Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_34_234">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
