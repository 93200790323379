<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_271_2795)">
            <path
                d="M9.99935 18.3333C14.6017 18.3333 18.3327 14.6023 18.3327 9.99996C18.3327 5.39759 14.6017 1.66663 9.99935 1.66663C5.39698 1.66663 1.66602 5.39759 1.66602 9.99996C1.66602 14.6023 5.39698 18.3333 9.99935 18.3333Z"
                stroke="currentcolor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M7.57422 7.49999C7.77014 6.94304 8.15685 6.47341 8.66585 6.17426C9.17485 5.87512 9.7733 5.76577 10.3552 5.86558C10.9371 5.96539 11.4649 6.26792 11.8451 6.71959C12.2253 7.17126 12.4334 7.74292 12.4326 8.33332C12.4326 9.99999 9.93255 10.8333 9.93255 10.8333"
                stroke="currentcolor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 14.1666H10.0083" stroke="currentcolor" stroke-width="1.66667" stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_271_2795">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>

</template>
